<template>
  <main>
    <section class="pricelist">
      <div class="title">
        <h1>Cenník</h1>
      </div>
    </section>
    <section v-scrollanimation class="pricelist-text p-84 mt-68">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <p>Obhliadku a predbežnú kalkuláciu ceny máte u nás <b>ZDARMA</b>. Ku každej objednávke <b>pristupujeme individuálne</b> a zohľadňujeme v nej všetky vaše preferencie. Výsledná cena sa preto môže v závislosti od vašich požiadaviek líšiť. Každý zákazník si môže priniesť aj <b>vlastný návrh.</b> Základ hrobu je železobetónový s použitím stavebnej chémie, na ktorý je možné položiť dlažbu.</p>
          </div>
          <div class="col-lg-12">
              <section class="section-header mt-68">
                  <h2>U nás nájdete </h2>
              </section>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="highlight">
                  <usersIcon/>
                  <p>Obhliadka</p>
              </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="highlight">
                  <calcIcon/>
                  <p>Vyhotovenie cenovej ponuky</p>
              </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="highlight">
                  <truckIcon/>
                  <p>Doprava a montáž</p>
              </div>
          </div>
          <div class="col-lg-12 mt-68">
            <h5>Konečná suma zahŕňa aj:</h5>
            <p>všetky potrebné <b>zemné práce</b> (demontáž, odvoz betónovej sute a zeminy z cintorína, základ), <b>oporné múry, dlažbu, žulu, vázu, svietnik, písmo, úpravu terénu, montáž a dopravu.</b> </p>
          </div>
          <div class="col-lg-12 mt-44">
            <h5>Písmo:</h5>
            <p>Pieskované v 24-karátovom zlate, taktiež v striebornej alebo čiernej farbe.</p>
          </div>
          <div class="col-lg-12 mt-44">
            <p>Návrhy textu na pomník <b>konzultujeme prostredníctvom e-mailu, mms, prípadne osobne v kancelárii.</b> Po schválení textu a rozloženia sa realizuje pieskovanie pomníka.</p>
          </div>
          <div class="col-lg-12 mt-44">
            <h5>Benefity:</h5>
            <p>Ak ste už naše služby v minulosti využili, v prípade ďalšieho pohrebu máte:</p>
            <br>
          </div>
          <div class="col-lg-12">
            <p>- otvorenie, zatvorenie existujúceho hrobu a dopravu <b>ZDARMA</b>, platíte iba za pečatenie/izolovanie hrobu</p>
            <p>- pri <b>dopísaní textu</b> na nápisnú tabuľu máte demontáž, spätnú montáž nápisnej tabule a dopravu s tým spojenú taktiež <b>ZDARMA</b>, platíte iba za text.</p>
          </div>
        </div>
      </div>
      <ViewAction class="mt-68"/>
    </section>
    
  </main>
</template>

<script>
import usersIcon from '../assets/icons/users-solid.svg'
import calcIcon from '../assets/icons/calculator-solid.svg'
import truckIcon from '../assets/icons/truck-moving-solid.svg'
import ViewAction from '../components/ViewAction'
export default {
  name: 'Pricelist',
  components:{
        usersIcon, calcIcon, truckIcon, ViewAction
    }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.pricelist{
  @include overlay;
  @include views-title;
}
.pricelist-text{
  @include highlights;
  h5{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px !important;
  }
  p{
    font-size: 18px;
  }
  .highlight{
    p{
      font-size: 18px;
    }
  }
}
</style>
